<template>
  <div style="position:relative;" class="document-input" :class="small ? 'small' : ''">
    <div class="label" v-if="label"
         :class="{'label-error': !local_value && required}"
    >
      {{ label }}
    </div>
    <div style="position:relative;">
      <input type="text" :value="local_value | formatDate('MMMM YYYY')" readonly
             :class="{'error-input': !local_value && required}"
             :style="`min-height: ${minHeight}px; ${transparent ? 'background-color: transparent !important;' : ''}`"
      >
      <div class="input-icon">
        <DateComponentIcon v-model="local_value" :row_num="row_num" :disable="disabled" :monthly="true" @changeDate="changeDate"/>
      </div>
    </div>
  </div>
</template>

<script>
import {formatDate} from "@/filters";

export default {
  name: "document_month_input",
  props: {
    field: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: String,
      default: ''
    },
    row_num: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: true
    },
    minHeight: {
      type: Number,
      default: 28
    },
    label: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  components: {
    DateComponentIcon: () => import("@/components/tableComponent/DateComponentIcon"),
  },
  data() {
    return {
      local_value: this.value,
      active: false
    }
  },
  methods: {
    changeDate(payload) {
      this.$emit('input', payload.value)
      this.$emit('inputValue',
          {
            field: this.field,
            text: formatDate(this.local_value, this.time ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY'),
            name: 'selectedRow' }
      )
      this.$emit('userChange', {row_num: this.row_num, value: this.local_value})
      // if (payload) {
      //   const row = this.table_items.find(item => item.row_num === payload.row_num)
      //   if (row) {
      //     row.pay_date = payload.value
      //   }
      //   this.markRowEdited(payload.row_num)
      // }
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: false,
      handler(payload) {
        this.local_value = payload
        this.$emit('inputValue',
            {
              field: this.field,
              text: formatDate(payload, this.time ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY'),
              name: 'selectedRow' }
        )
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>